export const BALANCE_OPERATIONS_CATEGORIES = [
  {
    id: "marketing_plan",
    name: "Маркетинг-план",
  },
  {
    id: "transactions",
    name: "Транзакции",
  },
];
