<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AccountSelector from "@/components/AccountSelector";
import BalanceAccount from "@/components/balance/BalanceAccount.vue";
import BalanceGifteryAccount from "@/components/balance/BalanceGifteryAccount.vue";
import BalanceHistoryOperations from "@/components/balance/balance-history-operations/BalanceHistoryOperations.vue";

export default {
  name: "Balance",

  components: {
    BalanceHistoryOperations,
    BalanceGifteryAccount,
    AccountSelector,
    BalanceAccount,
  },

  computed: {
    ...mapState({
      selectedLocale: (state) => state.auth.selectedLocale,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    ...mapGetters({
      getLocaleAccounts: "auth/getLocaleAccounts",
      getUserGifteryAccount: "auth/getUserGifteryAccount",
    }),

    sortedLocaleAccounts() {
      let accounts = this.getLocaleAccounts(this.selectedLocale.id);

      const distributorAccountIndex = this.getLocaleAccounts(
        this.selectedLocale.id
      ).findIndex((account) => {
        return (
          account.account_type === "rc" && account.user_type === "distributor"
        );
      });

      if (distributorAccountIndex !== 0 && accounts.length > 1) {
        accounts[0] = this.getLocaleAccounts(this.selectedLocale.id)[
          distributorAccountIndex
        ];
        accounts[distributorAccountIndex] = this.getLocaleAccounts(
          this.selectedLocale.id
        )[0];
      }

      return accounts;
    },
  },

  methods: {
    ...mapActions({
      loadCurrentBalance: "balance/loadCurrentBalance",
    }),
  },
};
</script>

<template>
  <div class="balance">
    <div class="balance__accounts accounts default-content-padding">
      <BalanceAccount
        v-for="account in sortedLocaleAccounts"
        :key="account.id"
        :account="account"
      />

      <BalanceGifteryAccount
        v-if="getUserGifteryAccount"
        :account="getUserGifteryAccount"
      />
    </div>

    <div v-if="selectedBinaryAccount" class="balance__history history">
      <div class="account-selector-wrapper">
        <AccountSelector />
      </div>

      <div class="history__wrapper">
        <div v-if="selectedBinaryAccount" class="history__bill bill">
          <div class="bill__cv">
            <h6>Счет CV</h6>
            <p>
              {{ selectedBinaryAccount.cv_left.toLocaleString() }}/{{
                selectedBinaryAccount.cv_right.toLocaleString()
              }}
              <span>CV</span>
            </p>
          </div>

          <div class="bill__pv">
            <h6>Счет PV</h6>
            <p>
              {{ selectedBinaryAccount.pv.toLocaleString() }} <span>PV</span>
            </p>
          </div>
        </div>

        <BalanceHistoryOperations />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance {
  &__accounts {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 64px;
  }

  .history {
    &__wrapper {
      display: flex;
      flex-direction: column;
      background-color: $background-white;
      padding: $space-xxl;

      @media (min-width: 426px) and (max-width: 576px) {
        padding: $space-xl;
      }

      @media (max-width: 425px) {
        padding: $space-xl $space-l;
      }

      @media (max-width: 375px) {
        padding: $space-xl $space-m;
      }
    }

    &__bill {
      display: flex;
      margin-bottom: $space-xxxl;

      @media (max-width: 576px) {
        margin-bottom: $space-xxl;
      }
    }

    .bill {
      &__cv {
        margin-right: $space-xl;
      }

      &__cv,
      &__pv {
        h6 {
          @include text-2;
          margin-bottom: $space-xxs;
          color: $light-sixth;
        }

        p {
          @include title-3;
          color: $dark-primary;
        }
      }

      &__cv span {
        color: $money-cv;
      }

      &__pv span {
        color: $money-pv;
      }
    }

    &__list {
      max-width: 708px;
    }

    &__item {
      &:not(:last-of-type) {
        margin-bottom: $space-l;
      }
    }

    &__btn {
      width: 150px;
      margin-top: $space-xxl;

      @media (max-width: 768px) {
        margin: $space-xxl auto 0;
      }

      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }
}

@media (max-width: 900px) {
  .balance {
    &__accounts {
      margin-bottom: 40px;
    }
  }
}
</style>
