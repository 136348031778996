<script>
import { mapState } from "vuex";
import formatAccountType from "@/package/helpers/format-account-type.js";
import Popup from "@/components/helpers/Popup";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import TooltipUpBalance from "@/components/tooltips/TooltipUpBalance.vue";

export default {
  components: {
    TooltipUpBalance,
    MainButton,
    MainButtonIcon,
    Popup,
  },

  props: {
    account: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isShowPopup: false,
      isExpanded: false,
      isOverflowHidden: false,
      isShowRefillBalance: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    accountType() {
      return formatAccountType(this.account);
    },

    isShowButtons() {
      return (
        this.account.user_type === "distributor" &&
        this.account.account_type !== "cooperative"
      );
    },

    formattedSummaryActive() {
      return `${this.account.summary_active.toLocaleString("ru-RU")} RC`;
    },

    formattedSummaryHold() {
      return `${this.account.summary_hold.toLocaleString("ru-RU")} RC`;
    },

    showOutputButton() {
      return this.user.tariff && this.user.tariff.code !== "free_c";
    },
  },

  methods: {
    copyAccountNumber(accountNumber) {
      navigator.clipboard.writeText(accountNumber).then(() => {
        this.isShowPopup = true;

        setTimeout(() => {
          this.isShowPopup = false;
        }, 1500);
      });
    },

    collapseAccount() {
      if (this.isShowRefillBalance || this.isExpanded) {
        return;
      }

      this.isOverflowHidden = true;

      this.isExpanded = !this.isExpanded;

      if (!this.isExpanded) {
        this.isShowRefillBalance = false;
      }

      if (this.isExpanded) {
        setTimeout(() => {
          this.isOverflowHidden = false;
        }, 110);
      }
    },
  },

  mounted() {
    if (this.accountType === "Дистрибьюторский счет") {
      this.isExpanded = true;
    }
  },
};
</script>

<template>
  <div
    class="balance-account"
    :class="[
      { 'balance-account_expanded': isExpanded },
      {
        'balance-account_expanded_small': isExpanded && !isShowButtons,
      },
      { 'balance-account_overflow-hidden': isOverflowHidden },
    ]"
    @click="collapseAccount"
  >
    <div class="balance-account__title">
      <p>{{ accountType }}</p>

      <div class="balance-account__copy">
        <Popup v-if="isShowPopup" description="Номер счета скопирован" />

        <MainButtonIcon
          class="balance-account__button-copy"
          @click="copyAccountNumber(account.id)"
        >
          <p>Скопировать номер счета</p>
          <span class="icon-copy" />
        </MainButtonIcon>
      </div>

      <MainButtonIcon
        class="balance-account__button-arrow"
        @click="isExpanded = !isExpanded"
      >
        <span
          class="icon-arrow"
          :class="{ 'icon-arrow_rotated': isExpanded }"
        />
      </MainButtonIcon>
    </div>

    <div class="balance-account__balance balance">
      <h3 class="balance__current">
        {{ formattedSummaryActive }}
      </h3>
      <p v-if="isExpanded" class="balance__withdrawal">
        В ожидании:<span>{{ formattedSummaryHold }}</span>
      </p>
    </div>

    <div v-if="isExpanded && isShowButtons" class="balance-account__buttons">
      <TooltipUpBalance
        v-if="isShowRefillBalance"
        class="test"
        title="Пополнение баланса"
        description="Пополните баланс через банковскую карту"
        :tooltip-position="'bottom'"
        @close="isShowRefillBalance = false"
      />

      <MainButton
        title="Пополнить"
        color="gold"
        @click="isShowRefillBalance = true"
      />

      <MainButton
        v-if="showOutputButton"
        class="balance__btn-withdraw"
        title="Вывести"
        color="dark"
        @click="$router.push({ name: 'BalanceOutput' })"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-account {
  max-width: 622px;
  width: 100%;
  background-color: $light-primary;
  border-radius: 16px;
  padding: 16px 24px;
  cursor: pointer;
  position: relative;
  height: 78px;
  transition: all 0.1s ease-out;
  overflow: visible;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    > p {
      @include text-2;
      color: $dark-primary;
    }
  }

  &__copy {
    position: relative;

    .popup {
      left: -12px;
      max-width: 240px;
    }
  }

  &__button-copy {
    > p {
      @include text-2;
      color: $dark-fifth;
      display: none;
    }

    .icon-copy {
      width: 16px;
      height: 16px;
      background-color: $dark-sixth;
      margin-left: 4px;
    }

    &:hover {
      .icon-copy {
        background-color: $dark-primary;
      }
    }
  }

  &__button-arrow {
    margin-left: auto;
    position: absolute;
    right: 24px;
    top: 24px;
    transition: top 0.05s linear;

    .icon-arrow {
      width: 32px;
      height: 32px;
      background-color: $dark-fifth;
      transition: transform 0.1s linear;
      transform: rotate(-180deg);

      &_rotated {
        transform: rotate(0);
      }
    }

    &:hover {
      .icon-arrow {
        background-color: $dark-primary !important;
      }
    }
  }

  .balance {
    &__current {
      @include body-1-bold;
      color: $dark-primary;
    }

    &__withdrawal {
      @include body-1;
      color: $dark-fifth;

      > span {
        margin-left: 8px;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 8px;
    margin-top: 20px;
    position: relative;

    .main-button {
      max-width: 160px;
    }
  }

  &:hover {
    box-shadow: 0 0 30px rgba(169, 169, 169, 0.15);

    .balance-account__button-arrow {
      .icon-arrow {
        background-color: $dark-primary;
      }
    }
  }

  &_overflow-hidden {
    overflow: hidden;
  }

  &_expanded {
    padding: 24px;
    height: 212px;
    cursor: default;

    .balance-account__title {
      margin-bottom: 18px;

      > p {
        @include body-1;
      }
    }

    .balance {
      &__current {
        @include title-3;
        margin-bottom: 4px;
      }
    }

    &_small {
      height: 144px;
    }

    &:hover {
      box-shadow: none;

      .balance-account__button-arrow {
        .icon-arrow {
          background-color: $dark-fifth;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .balance-account {
    height: 73px;

    .balance-account__button-arrow {
      top: 20px;
    }

    &_expanded {
      height: 204px;

      .balance-account__button-arrow {
        top: 24px;
      }

      &_small {
        height: 136px;
      }
    }
  }
}

@media (max-width: 576px) {
  .balance-account {
    &__buttons {
      flex-direction: column;

      .main-button {
        max-width: 100%;
      }
    }

    &_expanded {
      height: 278px;

      .balance-account__title {
        flex-direction: column;
        align-items: flex-start;
      }

      .balance-account__button-copy {
        margin-top: 4px;

        > p {
          display: block;
        }
      }

      .balance-account__button-arrow {
        top: 24px;
      }

      &_small {
        height: 153px;
      }
    }
  }
}
</style>
