import { render, staticRenderFns } from "./BalanceHistoryItem.vue?vue&type=template&id=cbb2b676&scoped=true"
import script from "./BalanceHistoryItem.vue?vue&type=script&lang=js"
export * from "./BalanceHistoryItem.vue?vue&type=script&lang=js"
import style0 from "./BalanceHistoryItem.vue?vue&type=style&index=0&id=cbb2b676&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbb2b676",
  null
  
)

export default component.exports