<script>
import vClickOutside from "v-click-outside";
import MainSearch from "@/components/helpers/MainSearch.vue";
// import MainButtonIcon from "@/package/components/MainButtonIcon.vue";

export default {
  name: "MainFilterSelector",

  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    // MainButtonIcon,
    MainSearch,
  },

  emits: ["update:initialOptions"],

  props: {
    label: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: "",
    },

    initialOptions: {
      type: Array,
      default: () => [],
    },

    options: {
      type: Array,
      required: true,
    },

    padding: {
      type: String,
      default: "0 16px",
    },
  },

  data() {
    return {
      isShownOptions: false,
      selectedOptions: [],
      search: "",
      listOptions: [],
    };
  },

  computed: {
    selectorStyle() {
      return {
        padding: this.padding,
      };
    },

    formattedPlaceholder() {
      let selectedLabels = "";

      this.selectedOptions.forEach((option, index) => {
        selectedLabels +=
          this.selectedOptions.length - 1 === index
            ? `${option.name}`
            : `${option.name}, `;
      });

      return this.selectedOptions.length === 0
        ? this.placeholder
        : selectedLabels;
    },
  },

  watch: {
    search() {
      this.searchOption();
    },

    options: {
      handler(newValue) {
        this.listOptions = newValue;
      },
      deep: true,
    },

    initialOptions: {
      handler(newValue) {
        this.selectedOptions = newValue;
      },
      deep: true,
    },
  },

  methods: {
    showOptions() {
      this.isShownOptions = !this.isShownOptions;
    },

    searchOption() {
      this.listOptions = this.options;

      this.listOptions = this.listOptions.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    clearSearch() {
      this.search = "";
      this.listOptions = this.options;
    },

    checkOption(option) {
      return this.selectedOptions.find((item) => {
        return item.id === option.id;
      });
    },

    selectOption(option) {
      this.checkOption(option)
        ? this.deleteItem(option)
        : this.selectedOptions.push(option);

      this.$emit("update:initialOptions", this.selectedOptions);
    },

    deleteItem(item) {
      const index = this.selectedOptions.findIndex((updateOption) => {
        return updateOption.id === item.id;
      });

      this.selectedOptions.splice(index, 1);
    },

    // clearAllSelected() {
    //   this.selectedOptions = [];
    //   this.$emit("update:initialOptions", this.selectedOptions);
    // },

    hideItems() {
      this.isShownOptions = false;
    },

    // applySelected() {
    //   this.isShownOptions = false;
    //   this.$emit("update:initialOptions", this.selectedOptions);
    // },
  },

  mounted() {
    this.listOptions = this.options;
    this.selectedOptions = this.initialOptions;
  },
};
</script>

<template>
  <div
    v-click-outside="hideItems"
    class="filter-selector"
    :class="{ 'filter-selector_active': initialOptions.length > 0 }"
    :style="selectorStyle"
    @click="showOptions"
  >
    <label>{{ label }}</label>

    <span>{{ formattedPlaceholder }}</span>

    <span v-if="selectedOptions.length > 0" class="filter-selector__counter">
      {{ selectedOptions.length }}
    </span>

    <span class="icon-arrow" :class="{ 'icon-arrow_switch': isShownOptions }" />

    <div
      v-if="isShownOptions"
      class="filter-selector__options options"
      @click.stop
    >
      <!--<div
        v-if="selectedOptions.length > 0"
        class="options__selected selected"
      >
        <div class="selected__selected-list">
          <div
            v-for="selected in selectedOptions"
            :key="selected.id"
            class="selected__item"
          >
            {{ selected.name }}

            <MainButtonIcon @click="deleteItem(selected)">
              <span class="icon-close" />
            </MainButtonIcon>
          </div>
        </div>

        <MainButtonIcon class="selected__clear-all" @click="clearAllSelected">
          <span class="icon-close" />
        </MainButtonIcon>
      </div>-->

      <MainSearch
        v-model="search"
        :background-color="'$light-second'"
        :placeholder="'Поиск'"
        :is-show-button-search="false"
        @clear="clearSearch"
      />

      <div v-if="options.length > 0" class="options__items">
        <div>
          <div
            v-for="option in listOptions"
            :key="option.id"
            class="options__item"
            @click="selectOption(option)"
          >
            <div
              class="item__checkbox"
              :class="{ item__checkbox_check: checkOption(option) }"
            >
              <span v-if="checkOption(option)" class="icon-check"></span>
            </div>

            <p class="item__label">
              {{ option.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filter-selector {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  color: $dark-primary;
  background: $light-second;
  border: 1px solid $light-fifth;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  height: 48px;
  margin-top: 25px;

  &_active {
    //background: $service-onlineLight;
    //border: 1px solid $gold-main;
  }

  label {
    position: absolute;
    top: -25px;
    left: 0;
    @include caption-1;
    color: $dark-sixth;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
    text-overflow: ellipsis;
    @include body-1;
  }

  .icon-arrow {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: $dark-primary;
    margin-left: auto;
    transform: rotate(180deg);

    &_switch {
      transform: rotate(0);
    }
  }

  &__counter {
    height: 20px;
    display: flex;
    align-items: center;
    margin-left: 4px;
    background: $gold-main;
    border: 1px solid $gold-dark;
    border-radius: 10px;
    @include caption-1;
    padding: 0 8px;
    line-height: 100%;
  }

  .options {
    height: auto;
    position: absolute;
    width: calc(100% + 2px);
    top: calc(100% + 4px);
    left: 0;
    min-width: 260px;
    //max-width: 320px;
    padding: 16px 0;
    background: $light-primary;
    border: 1px solid $light-third;
    box-shadow: 0 0 30px rgba(169, 169, 169, 0.15);
    border-radius: 8px;
    overflow: hidden;
    z-index: 999;
    display: flex;
    flex-direction: column;

    &__items {
      max-height: 212px;
      overflow: auto;
    }

    h4 {
      display: none;
    }

    &__item {
      cursor: pointer;
      @include body-1;
      padding: 8px 16px;
      display: flex;
      flex-direction: row;
      gap: 8px;

      &:hover {
        background: $light-second;
      }
    }

    .main-search {
      width: calc(100% - 32px);
      margin: 0 auto 16px auto;
    }

    .item__checkbox {
      min-width: 16px;
      width: 16px;
      height: 16px;
      background: $light-second;
      border: 1px solid $light-sixth;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3px;

      &_check {
        background: $gold-main;
        border: 1px solid $gold-main;
      }
    }

    .item__label {
      width: calc(100% - 16px);
    }

    .icon-check {
      width: 14px;
      height: 14px;
      background: $dark-primary;
      display: inline-block;
    }

    > svg {
      margin: 0 0 0 auto;
    }

    h6 {
      padding: 0 16px;
      margin-bottom: 16px;
      @include body-1;
      color: $dark-fifth;
    }
  }

  .selected {
    width: 100%;
    margin-bottom: 16px;
    padding: 0 16px 16px;
    border-bottom: 1px solid $light-fourth;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    cursor: default;

    &__selected-list {
      width: fit-content;
      max-width: calc(100% - 40px);
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4px;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 3px 4px;
      background: $light-third;
      border-radius: 2px;
      @include caption-1;
      cursor: pointer;
      white-space: nowrap;
    }

    &__clear-all {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $light-third;
      border-radius: 100%;
      cursor: pointer;
      margin-left: 16px;

      p {
        display: none;
      }
    }
  }

  .icon-close {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: $dark-third;
  }

  .main-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: $light-fifth;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  margin: 16px;
}

@media (max-width: 1023px) {
  .filter-selector {
    margin-top: 22px;
  }
}
</style>
