var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"balance-account",class:[
    { 'balance-account_expanded': _vm.isExpanded },
    {
      'balance-account_expanded_small': _vm.isExpanded && !_vm.isShowButtons,
    },
    { 'balance-account_overflow-hidden': _vm.isOverflowHidden },
  ],on:{"click":_vm.collapseAccount}},[_c('div',{staticClass:"balance-account__title"},[_c('p',[_vm._v(_vm._s(_vm.accountType))]),_c('div',{staticClass:"balance-account__copy"},[(_vm.isShowPopup)?_c('Popup',{attrs:{"description":"Номер счета скопирован"}}):_vm._e(),_c('MainButtonIcon',{staticClass:"balance-account__button-copy",on:{"click":function($event){return _vm.copyAccountNumber(_vm.account.id)}}},[_c('p',[_vm._v("Скопировать номер счета")]),_c('span',{staticClass:"icon-copy"})])],1),_c('MainButtonIcon',{staticClass:"balance-account__button-arrow",on:{"click":function($event){_vm.isExpanded = !_vm.isExpanded}}},[_c('span',{staticClass:"icon-arrow",class:{ 'icon-arrow_rotated': _vm.isExpanded }})])],1),_c('div',{staticClass:"balance-account__balance balance"},[_c('h3',{staticClass:"balance__current"},[_vm._v(" "+_vm._s(_vm.formattedSummaryActive)+" ")]),(_vm.isExpanded)?_c('p',{staticClass:"balance__withdrawal"},[_vm._v(" В ожидании:"),_c('span',[_vm._v(_vm._s(_vm.formattedSummaryHold))])]):_vm._e()]),(_vm.isExpanded && _vm.isShowButtons)?_c('div',{staticClass:"balance-account__buttons"},[(_vm.isShowRefillBalance)?_c('TooltipUpBalance',{staticClass:"test",attrs:{"title":"Пополнение баланса","description":"Пополните баланс через банковскую карту","tooltip-position":'bottom'},on:{"close":function($event){_vm.isShowRefillBalance = false}}}):_vm._e(),_c('MainButton',{attrs:{"title":"Пополнить","color":"gold"},on:{"click":function($event){_vm.isShowRefillBalance = true}}}),(_vm.showOutputButton)?_c('MainButton',{staticClass:"balance__btn-withdraw",attrs:{"title":"Вывести","color":"dark"},on:{"click":function($event){return _vm.$router.push({ name: 'BalanceOutput' })}}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }