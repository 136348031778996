<script>
import { mapState, mapActions } from "vuex";
import Popup from "@/components/helpers/Popup";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import MainProgressBar from "@/components/helpers/MainProgressBar.vue";

export default {
  components: {
    MainProgressBar,
    MainButtonIcon,
    Popup,
  },

  props: {
    account: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isShowPopup: false,
      isExpanded: false,
      isOverflowHidden: false,
      isShowRefillBalance: false,
    };
  },

  watch: {
    selectedBinaryAccount: {
      deep: true,
      handler() {
        this.loadGifteryCycles({
          binaryAccount: this.selectedBinaryAccount.id,
        });
      },
    },
  },

  computed: {
    ...mapState({
      gifteryCycles: (state) => state.balance.gifteryCycles,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    formattedSummaryActive() {
      return `${this.account.summary_active.toLocaleString("ru-RU")} RC`;
    },
  },

  methods: {
    ...mapActions({
      loadGifteryCycles: "balance/loadGifteryCycles",
    }),

    copyAccountNumber(accountNumber) {
      navigator.clipboard.writeText(accountNumber).then(() => {
        this.isShowPopup = true;

        setTimeout(() => {
          this.isShowPopup = false;
        }, 1500);
      });
    },

    collapseAccount() {
      if (this.isShowRefillBalance || this.isExpanded) {
        return;
      }

      this.isOverflowHidden = true;

      this.isExpanded = !this.isExpanded;

      if (!this.isExpanded) {
        this.isShowRefillBalance = false;
      }

      if (this.isExpanded) {
        setTimeout(() => {
          this.isOverflowHidden = false;
        }, 110);
      }
    },
  },

  mounted() {
    this.loadGifteryCycles({ binaryAccount: this.selectedBinaryAccount.id });
  },
};
</script>

<template>
  <div
    class="balance-giftery-account"
    :class="[
      { 'balance-giftery-account_expanded': isExpanded },
      { 'balance-giftery-account_overflow-hidden': isOverflowHidden },
    ]"
    @click="collapseAccount"
  >
    <div class="balance-giftery-account__title">
      <p>Подарочный счет</p>

      <div class="balance-giftery-account__copy">
        <Popup v-if="isShowPopup" description="Номер счета скопирован" />

        <MainButtonIcon
          class="balance-giftery-account__button-copy"
          @click="copyAccountNumber(account.id)"
        >
          <p>Скопировать номер счета</p>
          <span class="icon-copy" />
        </MainButtonIcon>
      </div>

      <MainButtonIcon
        class="balance-giftery-account__button-arrow"
        @click="isExpanded = !isExpanded"
      >
        <span
          class="icon-arrow"
          :class="{ 'icon-arrow_rotated': isExpanded }"
        />
      </MainButtonIcon>
    </div>

    <div class="balance-giftery-account__balance balance">
      <h3 class="balance__current">
        {{ formattedSummaryActive }}
      </h3>

      <MainButtonIcon
        v-if="isExpanded"
        class="balance__button-info"
        @click="$router.push('/documents/marketing')"
      >
        <span class="icon-info"></span>
      </MainButtonIcon>
    </div>

    <a
      v-if="isExpanded"
      class="balance-giftery-account__link"
      href="https://rc.shopping/"
      target="_blank"
    >
      Перейти к покупкам
    </a>

    <div v-if="isExpanded" class="balance-giftery-account__progress-bar">
      <MainProgressBar
        v-if="gifteryCycles"
        :current-value="+(gifteryCycles.current_position / 10).toFixed(1)"
        :max-value="15"
        :text="'Циклов до начисления:'"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-giftery-account {
  max-width: 622px;
  width: 100%;
  background-color: $light-primary;
  border-radius: 16px;
  padding: 16px 24px;
  cursor: pointer;
  position: relative;
  height: 78px;
  transition: all 0.1s ease-out;
  overflow: visible;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    > p {
      @include text-2;
      color: $dark-primary;
    }
  }

  &__copy {
    position: relative;

    .popup {
      left: -12px;
      max-width: 240px;
    }
  }

  &__button-copy {
    > p {
      @include text-2;
      color: $dark-fifth;
      display: none;
    }

    .icon-copy {
      width: 16px;
      height: 16px;
      background-color: $dark-sixth;
      margin-left: 4px;
    }

    &:hover {
      .icon-copy {
        background-color: $dark-primary;
      }
    }
  }

  &__button-arrow {
    margin-left: auto;
    position: absolute;
    right: 24px;
    top: 24px;
    transition: top 0.05s linear;

    .icon-arrow {
      width: 32px;
      height: 32px;
      background-color: $dark-fifth;
      transition: transform 0.1s linear;
      transform: rotate(-180deg);

      &_rotated {
        transform: rotate(0);
      }
    }

    &:hover {
      .icon-arrow {
        background-color: $dark-primary !important;
      }
    }
  }

  .balance {
    display: flex;
    align-items: center;

    &__current {
      @include body-1-bold;
      color: $dark-primary;
    }

    &__button-info {
      margin-left: 8px;
    }

    .icon-info {
      height: 24px;
      width: 24px;
      background-color: $dark-sixth;
    }
  }

  &__link {
    color: $blue;
    text-decoration: none;
    @include body-1;
  }

  &__progress-bar {
    margin-top: 16px;
  }

  &:hover {
    box-shadow: 0 0 30px rgba(169, 169, 169, 0.15);

    .balance-giftery-account__button-arrow {
      .icon-arrow {
        background-color: $dark-primary;
      }
    }
  }

  &_overflow-hidden {
    overflow: hidden;
  }

  &_expanded {
    padding: 24px;
    height: 200px;
    cursor: default;

    .balance-giftery-account__title {
      margin-bottom: 18px;

      > p {
        @include body-1;
      }
    }

    .balance {
      margin-bottom: 4px;

      &__current {
        @include title-3;
      }
    }

    &:hover {
      box-shadow: none;

      .balance-giftery-account__button-arrow {
        .icon-arrow {
          background-color: $dark-fifth;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .balance-giftery-account {
    height: 73px;

    .balance-giftery-account__button-arrow {
      top: 20px;
    }

    &_expanded {
      height: 192px;

      .balance-giftery-account__button-arrow {
        top: 24px;
      }
    }
  }
}

@media (max-width: 576px) {
  .balance-giftery-account {
    &_expanded {
      height: 205px;

      .balance-giftery-account__title {
        flex-direction: column;
        align-items: flex-start;
      }

      .balance-giftery-account__button-copy {
        margin-top: 4px;

        > p {
          display: block;
        }
      }

      .balance-giftery-account__button-arrow {
        top: 24px;
      }
    }
  }
}
</style>
