<template>
  <div class="popup" :class="{ popup__small: size === 'small' }">
    <div v-if="title" class="popup__title">{{ title }}</div>
    <div v-if="description" class="popup__description">{{ description }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },

    description: {
      type: String,
      required: true,
    },

    size: {
      type: String,
      default: "big",
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  width: 320px;
  padding: 20px;
  background-color: $light-primary;
  border-radius: 8px;
  position: absolute;
  bottom: calc(100% + 10px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 9999;

  &__small {
    width: 100%;
    padding: $space-inset-s;

    > .popup__description {
      @include caption-1();
    }
    &:before {
      border: 6px solid transparent !important;
      border-bottom: 4px solid $light-primary !important;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    border: 8px solid transparent;
    bottom: 0;
    top: 100%;
    transform: scaleY(-1);
    border-bottom: 8px solid $light-primary;
  }

  &__close {
    position: absolute;
    right: $space-l;
    top: $space-l;
    display: flex;
    cursor: pointer;
  }

  &__title {
    color: $dark-primary;
    margin: 0 0 $space-xs;
    @include body-1-bold;
  }

  &__description {
    color: $dark-fifth;
    @include text-2;
  }

  &__button {
    margin: $space-m 0 0;

    > button {
      width: 175px;
    }
  }
}
</style>
