<script>
import { mapState, mapActions } from "vuex";
import { format } from "date-fns";
import ruLocale from "date-fns/locale/ru";
import BalanceHistoryItem from "@/components/balance/balance-history-operations/BalanceHistoryItem.vue";
import TransitionHeight from "@/components/helpers/TransitionHeight.vue";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  components: { TransitionHeight, BalanceHistoryItem, MainButton },

  props: {
    day: {
      type: Object,
      required: true,
    },

    combinations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isExpanded: false,
      operations: [],
      page: 1,

      isShowButtonMore: true,
      isLoadingMoreOperations: false,
    };
  },

  computed: {
    ...mapState({
      dayOperations: (state) => state.balance.dayOperations,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    formattedDate() {
      return `${format(new Date(this.day.date), "d", {
        locale: ruLocale,
      })} ${format(new Date(this.day.date), "MMMM", {
        locale: ruLocale,
      })}`;
    },
  },

  methods: {
    ...mapActions({
      loadDayOperations: "balance/loadDayOperations",
    }),

    showOperationsByDay() {
      if (this.isExpanded === false) {
        if (this.isShowButtonMore === false || this.operations.length !== 0) {
          this.isExpanded = true;
        } else {
          this.loadDayOperations({
            binaryAccount: this.selectedBinaryAccount.id,
            combinations: this.combinations,
            day: this.day.date,
            page: 1,
          }).then(() => {
            if (this.dayOperations.length < 15) {
              this.isShowButtonMore = false;
            }

            this.operations = [...this.operations, ...this.dayOperations];
            this.isExpanded = true;
          });
        }
      } else {
        this.isExpanded = false;
      }
    },

    loadMoreOperations() {
      this.isLoadingMoreOperations = true;

      this.loadDayOperations({
        binaryAccount: this.selectedBinaryAccount.id,
        combinations: this.combinations,
        day: this.day.date,
        page: ++this.page,
      })
        .then(() => {
          if (
            this.dayOperations.length < 15 ||
            this.dayOperations.length === 0
          ) {
            this.isShowButtonMore = false;
          }

          this.operations = [...this.operations, ...this.dayOperations];
        })
        .finally(() => {
          this.isLoadingMoreOperations = false;
        });
    },
  },
};
</script>

<template>
  <div
    class="balance-history-day"
    :class="{ 'balance-history-day_expanded': isExpanded }"
  >
    <div class="balance-history-day__preview" @click="showOperationsByDay">
      <div class="balance-history-day__left">
        <p class="balance-history-day__date">
          {{ formattedDate }}
        </p>
        <span class="balance-history-day__count">
          {{ day.operations_count.toLocaleString() }}
        </span>
      </div>

      <div class="balance-history-day__right">
        <div class="balance-history-day__info">
          <p>{{ day.rc.toLocaleString() }} <span class="RC">RC</span></p>
          <p>{{ day.pv.toLocaleString() }} <span class="PV">PV</span></p>
          <p>
            {{ day.cv_left.toLocaleString() }}/
            {{ day.cv_right.toLocaleString() }}
            <span class="CV">CV</span>
          </p>
        </div>

        <span class="icon-arrow-bold" />
      </div>
    </div>

    <TransitionHeight>
      <div v-if="isExpanded" class="balance-history-day__operations">
        <BalanceHistoryItem
          v-for="operation in operations"
          :key="operation.operation_id"
          :operation="operation"
          class="balance-history-day__operation"
        />

        <MainButton
          v-if="isShowButtonMore"
          class="balance-history-day__button"
          :title="'Показать еще'"
          :color="'gold'"
          :loading="isLoadingMoreOperations"
          @click="loadMoreOperations"
        />
      </div>
    </TransitionHeight>
  </div>
</template>

<style lang="scss" scoped>
.balance-history-day {
  &__preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $background-grey;
    padding: 16px 24px;
    cursor: pointer;
    border-bottom: 1px solid $light-fourth;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 16px;

    > .icon-arrow-bold {
      width: 8px;
      height: 8px;
      background-color: $dark-sixth;
      transform: rotate(180deg);
      transition: transform 0.3s ease-out;
    }
  }

  &__date {
    @include text-2;
    color: $dark-third;
  }

  &__count {
    background-color: $light-primary;
    color: $dark-primary;
    padding: 0 6px;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    @include caption-1;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 16px;

    > p {
      @include text-2-bold;
      color: $dark-primary;

      > .RC {
        color: $gold-dark;
      }

      > .PV {
        color: $money-pv;
      }

      > .CV {
        color: $money-cv;
      }
    }
  }

  &__operations {
    height: 100px;
  }

  &__operation {
    &:not(:last-of-type) {
      border-bottom: 1px solid $light-fourth;
    }
  }

  &__button {
    margin: 12px 0 24px;
  }

  &_expanded {
    .balance-history-day {
      &__preview {
        border: none;
      }

      &__right {
        > .icon-arrow-bold {
          transform: rotate(0);
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .balance-history-day {
    &__preview {
      padding: 16px 12px;
    }

    &__right {
      gap: 8px;
    }

    &__info {
      gap: 8px;
    }
  }
}

@media (max-width: 374px) {
  .balance-history-day {
    &__info {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
</style>
