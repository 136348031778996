<script>
import { mapState } from "vuex";

export default {
  props: {
    operation: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      combinations: (state) => state.balance.combinations,
    }),

    isShowOperation() {
      return (
        this.operation.rc ||
        this.operation.pv ||
        this.operation.cv_left ||
        this.operation.cv_right
      );
    },

    formattedTime() {
      return new Date(this.operation.created_at).toLocaleString("ru", {
        timeZone: "UTC",
        hour: "numeric",
        minute: "numeric",
      });
    },

    formattedType() {
      return this.operation?.type || "Неизвестный тип операции";
    },

    formattedActor() {
      if (this.operation.transaction_type === "transfer_from") {
        return this.operation?.to?.account || "";
      }

      if (this.operation.transaction_type === "linear_bonus") {
        return `${this.operation?.from?.first_name || ""} ${
          this.operation?.from?.last_name || ""
        }`;
      }

      if (this.operation.transaction_type === "transfer") {
        if (this.operation.operation_type === "transfer_from") {
          return `${this.operation?.to?.account}`;
        } else if (this.operation.operation_type === "transfer_to") {
          return `${this.operation?.from?.account}`;
        }
      }

      return `${this.operation?.actor?.first_name || ""} ${
        this.operation?.actor?.last_name || ""
      }`;
    },

    formattedCV() {
      return this.operation.cv_left >= 0 || this.operation.cv_right >= 0
        ? `+${this.operation.cv_left.toLocaleString()}/${this.operation.cv_right.toLocaleString()} CV`
        : `${this.operation.cv_left.toLocaleString()}/${this.operation.cv_right.toLocaleString()} CV`;
    },

    formattedRC() {
      return this.operation.rc >= 0
        ? `+${this.operation.rc.toLocaleString()} RC`
        : `${this.operation.rc.toLocaleString()} RC`;
    },

    formattedPV() {
      return this.operation.pv >= 0
        ? `+${this.operation.pv.toLocaleString()} PV`
        : `${this.operation.pv.toLocaleString()} PV`;
    },
  },
};
</script>

<template>
  <div v-if="isShowOperation" class="balance-history-item">
    <div class="balance-history-item__left">
      <div class="balance-history-item__icon">
        <span class="icon-arrow-line" />
      </div>
      <div class="balance-history-item__operation operation">
        <p class="operation__name">
          {{ formattedType }}
        </p>
        <p class="operation__person">{{ formattedActor }}</p>
      </div>
    </div>
    <div class="balance-history-item__right">
      <p v-if="operation.rc" class="balance-history-item__amount">
        {{ formattedRC }}
      </p>
      <p v-if="operation.pv" class="balance-history-item__amount">
        {{ formattedPV }}
      </p>
      <p
        v-if="operation.cv_left || operation.cv_right"
        class="balance-history-item__amount"
      >
        {{ formattedCV }}
      </p>
      <p class="balance-history-item__time">{{ formattedTime }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-history-item {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &__left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__icon {
    min-width: 32px;
    width: 32px;
    height: 32px;
    background-color: $dark-primary;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > .icon-arrow-line {
      width: 24px;
      height: 24px;
      background-color: $light-primary;
    }
  }

  .operation {
    &__name {
      @include body-1;
      color: $dark-primary;
    }

    &__person {
      margin-top: 2px;
      @include caption-1;
      color: $dark-fifth;
    }
  }

  &__amount {
    @include body-1;
    color: $dark-primary;
    margin-bottom: 4px;
    white-space: nowrap;
  }

  &__time {
    @include caption-1;
    color: $dark-third;
  }

  &_declined {
    .operation {
      &__name {
        color: $red;
      }
    }

    .balance-history-item {
      &__amount {
        color: $red;
      }
    }
  }
}

@media (max-width: 575px) {
  .balance-history-item {
    padding: 16px 12px;
  }
}
</style>
