<script>
import { mapState, mapActions } from "vuex";
import { set, startOfMonth } from "date-fns";
import { format } from "date-fns-tz";
import { debounce } from "throttle-debounce";
import { BALANCE_OPERATIONS_CATEGORIES } from "@/package/const/balance-operations-categories";
import BalancePeriodTotal from "@/components/balance/balance-history-operations/BalancePeriodTotal.vue";
import MainDateRange from "@/components/helpers/MainDateRange.vue";
import BalanceHistoryDay from "@/components/balance/balance-history-operations/BalanceHistoryDay.vue";
import MainFilterSelector from "@/components/helpers/MainFilterSelector.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";

export default {
  components: {
    MainLoader,
    MainFilterSelector,
    BalanceHistoryDay,
    MainDateRange,
    BalancePeriodTotal,
  },

  data() {
    return {
      BALANCE_OPERATIONS_CATEGORIES,

      availableDates: {
        start: null,
        end: null,
      },

      params: {
        operationsTypes: [],
        date: {
          start: "",
          end: "",
        },
      },
    };
  },

  watch: {
    "params.date": {
      deep: true,
      handler() {
        this.loadMonthOperationsDebounce();
        this.loadStatsDebounce();
      },
    },

    "params.operationsTypes": {
      deep: true,
      handler() {
        const categoriesIds = this.params.operationsTypes.map((type) => {
          return type.id;
        });

        this.loadCombinations(categoriesIds).then(() => {
          this.loadMonthOperationsDebounce();
          this.loadStatsDebounce();
        });
      },
    },

    selectedBinaryAccount: {
      deep: true,
      handler() {
        this.loadStats(this.formattedParams);
        this.loadMonthOperations(this.formattedParams);
      },
    },
  },

  computed: {
    ...mapState({
      combinations: (state) => state.balance.combinations,
      stats: (state) => state.balance.stats,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      monthOperations: (state) => state.balance.monthOperations,
      statusMonthOperations: (state) => state.balance.statusMonthOperations,
    }),

    isShowButtonReset() {
      return !(
        this.params.operationsTypes.length === 0 &&
        (this.params.date?.start !== ""
          ? format(startOfMonth(new Date()), "dd.MM.yyyy") ===
            format(new Date(this.params.date.start), "dd.MM.yyyy")
          : false) &&
        (this.params.date?.end !== ""
          ? format(new Date(), "dd.MM.yyyy") ===
            format(new Date(this.params.date.end), "dd.MM.yyyy")
          : false)
      );
    },

    formattedParams() {
      let data = {};

      data.binaryAccount = this.selectedBinaryAccount.id;
      data.combinations = this.combinations;

      if (this.params.date?.start !== "" && this.params.date?.end !== "") {
        data.dateTo = format(
          set(new Date(this.params.date.end), {
            hours: 23,
            minutes: 59,
            seconds: 59,
            milliseconds: 0,
          }),
          "yyyy-MM-dd'T'HH:mm:ssxxxxx",
          {
            timeZone: "UTC",
          }
        );
        data.dateFrom = format(
          set(new Date(this.params.date.start), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }),
          "yyyy-MM-dd'T'HH:mm:ssxxxxx",
          {
            timeZone: "UTC",
          }
        );
      }

      return data;
    },

    reversedMonthOperations() {
      let reversedMonthOperations = this.monthOperations.slice(0);

      return reversedMonthOperations.reverse();
    },
  },

  methods: {
    ...mapActions({
      loadCombinations: "balance/loadCombinations",
      loadStats: "balance/loadStats",
      loadMonthOperations: "balance/loadMonthOperations",
    }),

    loadMonthOperationsDebounce: debounce(1000, function () {
      this.loadMonthOperations(this.formattedParams);
    }),

    loadStatsDebounce: debounce(1000, function () {
      this.loadStats(this.formattedParams);
    }),

    resetFilters() {
      const dateTo = new Date();
      const dateFrom = startOfMonth(new Date());

      this.params.operationsTypes = [];
      this.params.date = {
        start: dateFrom,
        end: dateTo,
      };
    },
  },

  mounted() {
    this.loadCombinations().then(() => {
      const dateTo = new Date();
      const dateFrom = startOfMonth(new Date());

      this.params.date = {
        start: dateFrom,
        end: dateTo,
      };
    });
  },
};
</script>

<template>
  <div class="balance-history-operations">
    <h4 class="balance-history-operations__title">История операций</h4>

    <div class="balance-history-operations__filters filters">
      <MainDateRange
        ref="date-range"
        v-model="params.date"
        label="Показать за период"
        :is-show-button-reset="false"
        :is-month-range="true"
      />

      <MainFilterSelector
        class="filters__selector"
        :options="BALANCE_OPERATIONS_CATEGORIES"
        :initial-options.sync="params.operationsTypes"
        label="Тип операций"
        placeholder="Все"
      />
    </div>

    <button
      v-if="isShowButtonReset"
      class="balance-history-operations__button-reset"
      @click="resetFilters"
    >
      <span class="icon-close-bold"></span>
      Сбросить
    </button>

    <BalancePeriodTotal
      v-if="stats"
      class="balance-history-operations__total"
      title="Всего за период"
      :unit-rc="stats.rc.toLocaleString()"
      :unit-pv="stats.pv.toLocaleString()"
      :unit-cv="`${stats.cv_left.toLocaleString()}/${stats.cv_right.toLocaleString()}`"
    />

    <div class="balance-history-operations__list">
      <template v-if="statusMonthOperations === 'LOADING'">
        <MainLoader />
      </template>
      <template
        v-else-if="
          monthOperations.length && statusMonthOperations === 'SUCCESS'
        "
      >
        <BalanceHistoryDay
          v-for="day in reversedMonthOperations"
          :key="day.date"
          :day="day"
          :combinations="combinations"
        />
      </template>
      <template
        v-else-if="
          monthOperations.length === 0 && statusMonthOperations === 'SUCCESS'
        "
      >
        <p>Нет операций за выбранный период</p>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-history-operations {
  max-width: 622px;

  &__title {
    @include title-3;
    color: $dark-primary;
    margin-bottom: 24px;
  }

  .filters {
    display: flex;
    align-items: center;
    gap: 12px;

    &__selector {
      width: 100%;
    }
  }

  &__total {
    margin-top: 32px;
  }

  &__button-reset {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    @include text-2;
    color: $dark-fifth;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: color 0.1s linear;
    margin-top: 12px;

    > .icon-close-bold {
      min-width: 24px;
      width: 24px;
      height: 24px;
      background-color: $dark-fifth;
      transition: background 0.1s linear;
    }

    &:hover {
      color: $dark-primary;

      span {
        background: $dark-primary;
      }
    }

    &:active {
      color: $dark-fourth;

      span {
        background: $dark-fourth;
      }
    }
  }

  &__list {
    margin-top: 32px;

    > p {
      @include body-1;
      color: $dark-third;
    }
  }
}

@media (max-width: 767px) {
  .balance-history-operations {
    .filters {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      ::v-deep .main-date-range {
        width: 100%;

        &__input {
          width: 100%;

          > input {
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
