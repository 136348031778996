<script>
export default {
  props: {
    maxValue: {
      type: Number,
      required: true,
    },

    currentValue: {
      type: Number,
      default: 0,
    },

    text: {
      type: String,
      default: "",
    },
  },

  computed: {
    formattedText() {
      return `${this.text} ${this.currentValue}/${this.maxValue}`;
    },

    backgroundWidthInPercentage() {
      return (this.currentValue / this.maxValue) * 100;
    },
  },
};
</script>

<template>
  <div class="main-progress-bar">
    <p>{{ formattedText }}</p>

    <span
      class="main-progress-bar__inner"
      :style="{ width: `${this.backgroundWidthInPercentage}%` }"
    ></span>
  </div>
</template>

<style lang="scss" scoped>
.main-progress-bar {
  height: 40px;
  max-width: 320px;
  width: 100%;
  background-color: $light-third;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  > p {
    @include text-2;
    z-index: 99;
  }

  &__inner {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $gold-main;
  }
}

@media (max-width: 900px) {
  .main-progress-bar {
    height: 36px;
  }
}
</style>
