<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    mobileColumn: {
      type: Boolean,
      default: true,
    },

    unitRc: {
      type: String,
      default: "",
    },

    unitPv: {
      type: String,
      default: "",
    },

    unitCv: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <div
    class="balance-period-total"
    :class="{ 'balance-period-total_column': mobileColumn }"
  >
    <p>{{ title }}</p>

    <div class="balance-period-total__info">
      <p v-if="unitRc" class="RC">{{ unitRc }} <span>RC</span></p>
      <p v-if="unitPv" class="PV">{{ unitPv }} <span>PV</span></p>
      <p v-if="unitCv" class="CV">{{ unitCv }} <span>CV</span></p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-period-total {
  padding: 32px;
  border-radius: 8px;
  background-color: $dark-primary;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    @include body-1-bold;
    color: $light-fifth;
  }

  &__info {
    display: flex;
    gap: 8px;

    > p {
      @include body-1-bold;
      color: $light-primary;
    }

    > .RC {
      span {
        color: $gold-main;
      }
    }

    > .PV {
      span {
        color: $money-pv;
      }
    }

    > .CV {
      span {
        color: $money-cv;
      }
    }
  }
}

@media (max-width: 575px) {
  .balance-period-total {
    padding: 24px;
    gap: 8px;

    &_column {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
</style>
